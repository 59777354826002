import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getOktaToken, selectAuthState } from "@/store/features/authSlice"
import {
  createCart,
  getCart,
  selectCartState,
  setCardStatus,
} from "@/store/features/cartSlice"
import usePrevious from "@/hooks/usePrevious"
import { getCurrency, isGuestTokenValid } from "@/utils/helper"
import { setCartLandingPagePath } from "@/utils/cart"
import useIsSsr from "@/hooks/useIsSsr"
import { getConfig } from "@/constants/config"

const usePageAuth = () => {
  const isSsr = useIsSsr()
  const dispatch = useDispatch()
  const { access_token: accessToken } = useSelector(selectAuthState)
  const { cart: activeCart, isCartLoading } = useSelector(selectCartState)
  const prevToken = usePrevious({ accessToken })

  // just for the dev testing purpose, if works fine then can only be proceed otherwise
  // one more pr will be raised to revert the change
  useEffect(() => {
    let timeoutId
    if (!isSsr) {
      timeoutId = setTimeout(() => {
        if (!accessToken || !isGuestTokenValid()) {
          dispatch(getOktaToken("guest"))
        }
      }, 5000)
    }
    ;() => {
      clearTimeout(timeoutId)
    }
  }, [accessToken, isSsr])

  const callInitAuth = async () => {
    const config = await getConfig()
    const disableCommerce = config?.general?.disableCommerce || false

    if (disableCommerce) return

    const hasNoActiveCart = () => {
      return (
        isGuestTokenValid() &&
        accessToken &&
        !activeCart?.id &&
        !activeCart?.cartExists
      )
    }
    if (
      hasNoActiveCart() ||
      (hasNoActiveCart() && prevToken?.accessToken !== accessToken)
    ) {
      if (!isCartLoading) {
        dispatch(setCardStatus(true))
        dispatch(getCart())
          .unwrap()
          .then(res => {
            if (
              isGuestTokenValid() &&
              accessToken &&
              res?.cartExists &&
              res?.cartExists === "false" &&
              res?.error === "No active cart exists."
            ) {
              getCurrency().then(currency => {
                const cartPayload = {
                  currency: currency,
                  taxMode: "ExternalAmount",
                }
                dispatch(createCart(cartPayload))
                  .unwrap()
                  .then(() => {
                    dispatch(setCardStatus(false))
                  })
              })
            } else {
              dispatch(setCardStatus(false))
            }
          })
      }
    }
    setCartLandingPagePath()
  }

  useEffect(() => {
    if (isSsr) return
    callInitAuth()
  }, [accessToken, isSsr])
}

export default usePageAuth
