import React from "react"
import LoadMore from "@/components/ProductList/v2/ProductListView/Pagination/LoadMore"
import Pagination from "@/components/ProductList/v2/ProductListView/Pagination/Pagination"

const ProductPagination = props => {
  return (
    <>
      <LoadMore {...props} />
      <Pagination {...props} />
    </>
  )
}

export default ProductPagination
