export const searchGetBackToTopAnalytics = type =>
  JSON.stringify({
    eventAction: `search results:${type}:main content area:back to top`,
    eventName: `search results:${type}:main content area:back to top`,
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: "back to top",
    internalLinkPosition: "search results",
    internalLinkType: "search results:navigation",
    internalLinkZoneName: `search results:${type}:main content area`,
    internalLinkURL: "n/a",
    clickInternalLinks: "true",
  })

export const technicalSpecGetBackToTopAnalytics = JSON.stringify({
  eventAction: "technical specs:main content area:back to top",
  eventName: "technical specs:main content area:back to top",
  eventType: "navigation",
  eventMsg: "n/a",
  eventStatus: "n/a",
  internalLinkName: "back to top",
  internalLinkPosition: "tech specs",
  internalLinkType: "technical specs:navigation",
  internalLinkZoneName: "technical specs:main content area",
  internalLinkURL: "n/a",
  clickInternalLinks: "true",
})

export const plpGetBackToTopAnalytics = JSON.stringify({
  eventAction: "plp:back to top",
  eventName: "plp:back to top",
  eventType: "navigation",
  eventMsg: "n/a",
  eventStatus: "n/a",
  internalLinkName: "back to top",
  internalLinkPosition: "plp",
  internalLinkType: "plp:navigation",
  internalLinkZoneName: "plp:promo tiles",
  internalLinkURL: "n/a",
  clickInternalLinks: "true",
})

export const defaultGetBackToTopAnalytics = JSON.stringify({
  eventAction: "support:help and faq:article list:back to top",
  eventName: "support:help and faq:article list:back to top",
  eventType: "navigation",
  eventMsg: "n/a",
  eventStatus: "n/a",
  internalLinkName: "back to top",
  internalLinkPosition: "support:help and faq",
  internalLinkType: "support:help and faq:navigation",
  internalLinkZoneName: "support:help and faq:article list",
  internalLinkURL: "n/a",
  clickInternalLinks: "true",
})

export const searchLoadMoreAnalyticsData =
  '{"eventAction":"search results:products:main content area:load more","eventName":"search results:products:main content area:load more","eventType":"navigation","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"load more","internalLinkPosition":"search results","internalLinkType":"search results:load more","internalLinkZoneName":"search results:products:main content area","internalLinkURL":"n/a","clickInternalLinks":"true"}'

export const technicalSpecLoadMoreAnalyticsData =
  '{"eventAction":"technical specs:main content area:load more","eventName":"technical specs:main content area:load more","eventType":"navigation","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"load more","internalLinkPosition":"tech specs","internalLinkType":"technical specs:load more","internalLinkZoneName":"technical specs:main content area","internalLinkURL":"n/a","clickInternalLinks":"true"}'

export const articleLoadMoreAnalyticsData = `{"eventAction":"support:help and faq:article list:load more","eventName":"support:help and faq:article list:load more","eventType":"navigation","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"load more","internalLinkPosition":"support:help and faq","internalLinkType":"support:help and faq:load more","internalLinkZoneName":"support:help and faq:promo tiles","internalLinkURL":"n/a","clickInternalLinks":"true"}`
export const defaultLoadMoreAnalyticsData =
  '{"eventAction":"plp load more","eventName":"plp load more","eventType":"navigation","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"load more","internalLinkPosition":"plp","internalLinkType":"plp:load more","internalLinkZoneName":"plp:promo tiles","internalLinkURL":"n/a","clickInternalLinks":"true"}'
export const getLoadMoreAnalyticsData = (page = "") => {
  let analyticsData = ""
  if (page === "search") {
    analyticsData = searchLoadMoreAnalyticsData
  } else if (page === "technicalspecs") {
    analyticsData = technicalSpecLoadMoreAnalyticsData
  } else if (page === "article") {
    analyticsData = articleLoadMoreAnalyticsData
  } else {
    analyticsData = defaultLoadMoreAnalyticsData
  }

  return analyticsData
}
