import { React, useEffect, useState } from "react"
import DOMPurify from "isomorphic-dompurify"
import _isEmpty from "lodash/isEmpty"
import useIsSsr from "@/hooks/useIsSsr"
import { getShortenedUrl } from "@/utils/helper"
import { COMPONENT_TYPES } from "@/constants"

const BreadCrumb = props => {
  const {
    data: { appliedCssClassNames = "", items = [] },
    fullData = {},
  } = props
  const {
    carousel: {
      carouselComponentProperties: {
        appliedCssClassNames: carouselClassName = "",
      } = {},
    } = {},
  } = fullData
  const isSsr = useIsSsr()
  const ITEM_TYPE = {
    breadcrumbList: "http://schema.org/BreadcrumbList",
    listItem: "http://schema.org/ListItem",
  }

  const [eventPageName, setEventPageName] = useState("")
  const [shortURLs, setShortURLs] = useState([])
  const [componentGroup, setComponentGroup] = useState([])
  const [columnClassNames, setColumnClassNames] = useState("")

  useEffect(() => {
    const urls = items.map(navItem => navItem.url)
    Promise.all(urls.map(getShortenedUrl)).then(res => setShortURLs(res))
  }, [items])

  useEffect(() => {
    if (isSsr) return
    setEventPageName(window.eventPageType)
  }, [isSsr])
  // #ToDo Hard coding zone value once received data from json will change
  const zone = "hero banner"

  useEffect(() => {
    if (!_isEmpty(fullData)) {
      Object.keys(fullData).forEach(key => {
        setComponentGroup(cmpGroup => [...cmpGroup, fullData[key][":type"]])
      })
    }
  }, [fullData])

  useEffect(() => {
    if (componentGroup.length > 0) {
      if (
        componentGroup.find(a => a?.includes(COMPONENT_TYPES.BREADCRUMB)) &&
        componentGroup.find(a => a?.includes(COMPONENT_TYPES.TEASER)) &&
        !columnClassNames?.includes(
          "aem-GridColumn aem-GridColumn--default--12"
        )
      ) {
        setColumnClassNames(
          className =>
            (className += " aem-GridColumn aem-GridColumn--default--12")
        )
      }
      if (
        componentGroup.find(a => a?.includes(COMPONENT_TYPES.BREADCRUMB)) &&
        componentGroup.find(a => a?.includes(COMPONENT_TYPES.CAROUSEL)) &&
        carouselClassName === "carousel-navigation-cta-left-right"
      ) {
        setColumnClassNames(className => (className += " breadcrumb-overlay"))
      }
      if (
        componentGroup.find(a => a?.includes(COMPONENT_TYPES.BREADCRUMB)) &&
        componentGroup.find(a => a?.includes(COMPONENT_TYPES.CAROUSEL)) &&
        componentGroup.find(a => a?.includes(COMPONENT_TYPES.CONTAINER)) &&
        !columnClassNames?.includes(
          "aem-GridColumn aem-GridColumn--default--12"
        )
      ) {
        setColumnClassNames(
          className =>
            (className += " aem-GridColumn aem-GridColumn--default--12")
        )
      }
    }
  }, [componentGroup])

  const getEventInfo = (navItem, index) => {
    if (isSsr) {
      return JSON.stringify({
        clickInternalLinks: "true",
        eventAction: eventPageName + ":" + zone + ":breadcrumb",
        eventName: eventPageName + ":" + zone + ":breadcrumb",
        eventType: eventPageName,
        internalLinkName: navItem?.title,
        internalLinkPosition: "main content area",
        internalLinkType: window.storeLinkType
          ? window.storeLinkType
          : eventPageName + ":breadcrumb",
        internalLinkURL: navItem?.url,
        internalLinkZoneName: eventPageName + ":" + zone,
      })
    }
  }

  return (
    <>
      <div
        className={`breadcrumb ${
          appliedCssClassNames ? appliedCssClassNames : ""
        }${columnClassNames}`}
      >
        {items.length > 0 ? (
          <nav
            id="breadcrumb"
            className="cmp-breadcrumb"
            aria-label="Breadcrumb"
          >
            <ol
              className="cmp-breadcrumb__list"
              itemScope
              itemType={ITEM_TYPE.breadcrumbList}
            >
              {items.map((navItem, index) => {
                const isLastItem = index === items.length - 1
                const isActive = navItem.active
                const shouldRenderSlash =
                  !isActive && !isLastItem && !items[index + 1].active

                return (
                  <li
                    key={`breadcrumb-item-${index}`}
                    className={`cmp-breadcrumb__item${
                      navItem.active ? " cmp-breadcrumb__item--active" : ""
                    }`}
                    itemProp="itemListElement"
                    itemScope
                    itemType={ITEM_TYPE.listItem}
                  >
                    <a
                      href={shortURLs[index]}
                      className="cmp-breadcrumb__item-link"
                      itemProp="item"
                      data-zone={zone}
                      aria-label={navItem.title}
                      aria-current={navItem.active ? "page" : null}
                      data-gbh-data-layer={
                        !isSsr && getEventInfo(navItem, index)
                      }
                    >
                      <span
                        itemProp="name"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(navItem?.title),
                        }}
                      />
                    </a>{" "}
                    {shouldRenderSlash && (
                      <>
                        <span
                          className="cmp-breadcrumb-slash"
                          aria-hidden="true"
                        />
                        &nbsp;
                      </>
                    )}
                    <meta itemProp="position" content={index + 1} />
                  </li>
                )
              })}
            </ol>
          </nav>
        ) : null}
      </div>
    </>
  )
}

export default BreadCrumb
