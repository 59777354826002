import React from "react"
import { isMobile, isTablet } from "react-device-detect"
import filterIcon from "@/public/icons/filter.svg"
import cx from "classnames"
import { pushAnalyticsObjToDataLayer } from "@/utils/helper"
import { useDeviceWidthOnResize } from "@/hooks/useDeviceWidthOnResize"

const ShowHide = ({
  showFilter,
  staticTexts,
  setShowFilter,
  ariaHaspopup,
  ariaExpanded,
  page,
  type,
  isSearchTab,
  isFaq = false,
}) => {
  const filterButtonClass = cx({
    "product-listing__filter-toggle-text": true,
    "product-listing__filter-toggle-text--show": !showFilter,
  })
  const [width] = useDeviceWidthOnResize()
  const customProps = {}
  if (ariaHaspopup)
    Object.assign(customProps, { "aria-haspopup": isTablet || isMobile })
  if (ariaExpanded)
    Object.assign(customProps, {
      "aria-expanded": isTablet || isMobile ? false : showFilter,
    })
  const getShowHideAnalyticsData = (showFilterFlag, needJson = false) => {
    let analyticsData = {
      eventAction: `support:help and faq:filters:${
        showFilterFlag ? "show" : "hide"
      } filter`,
      eventName: `support:help and faq:filters:${
        showFilterFlag ? "show" : "hide"
      } filter`,
      eventType: "navigation",
      eventMsg: "n/a",
      eventStatus: "n/a",
      internalLinkName: `${showFilterFlag ? "show" : "hide"} filter`,
      internalLinkPosition: "support:help and faq",
      internalLinkType: `support:help and faq:${
        showFilterFlag ? "show" : "hide"
      }`,
      internalLinkZoneName: "support:help and faq:filters",
      internalLinkURL: "n/a",
      clickInternalLinks: "true",
    }
    if (page === "search") {
      const pagetype = type
      analyticsData = {
        eventAction: `search results:${pagetype}:filters:${
          showFilterFlag ? "show" : "hide"
        } filters`,
        eventName: `search results:${pagetype}:filters:${
          showFilterFlag ? "show" : "hide"
        } filters`,
        eventType: "navigation",
        eventStatus: "n/a",
        internalLinkName: `${showFilterFlag ? "show" : "hide"} filters`,
        internalLinkPosition: "search results page",
        internalLinkType: `search result page:${
          showFilterFlag ? "show filters" : "hide filters"
        }`,
        internalLinkZoneName: `search results:${pagetype}:filters`,
        internalLinkURL: "n/a",
        clickInternalLinks: "true",
      }
    }
    if (needJson) return analyticsData
    return JSON.stringify(analyticsData)
  }

  const addAnalyticsForShowHideFilter = showFilter => {
    const filterType = !showFilter ? "show" : "hide"
    const eventNameAndInternalLinkType = `plp:${filterType}`
    let eventInfo = {
      clickInternalLinks: "true",
      eventAction: `${eventNameAndInternalLinkType} filter`,
      eventName: `${eventNameAndInternalLinkType} filter`,
      eventType: "navigation",
      eventMsg: "n/a",
      eventStatus: "n/a",
      internalLinkName: `${filterType} filters`,
      internalLinkType: eventNameAndInternalLinkType,
      internalLinkURL: "n/a",
      internalLinkPosition: "plp",
      internalLinkZoneName: "plp:filters",
    }
    if (isSearchTab || isFaq) {
      eventInfo = getShowHideAnalyticsData(showFilter, true)
    }
    pushAnalyticsObjToDataLayer(eventInfo)
    setShowFilter(!showFilter)
  }

  return (
    <button
      id="showFilter"
      aria-label={!showFilter ? staticTexts.showFilter : staticTexts.hideFilter}
      className="product-listing__filter-toggle"
      onClick={() => addAnalyticsForShowHideFilter(showFilter)}
      {...customProps}
    >
      {width > 991 ? (
        <ul className={filterButtonClass}>
          <li className={showFilter ? "show" : "hide"}>
            {staticTexts?.hideFilter}
          </li>
          <li className={!showFilter ? "show" : "hide"}>
            {staticTexts?.showFilter}
          </li>
        </ul>
      ) : null}
      <img
        src={filterIcon?.src}
        className="product-listing__filter-toggle-icon"
        alt="Toggle Filter"
      />
    </button>
  )
}
export default ShowHide
