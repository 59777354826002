import { resourceFacetLabels } from "@/constants/index"

const getSortList = staticTexts => [
  { name: staticTexts.featured },
  { name: staticTexts.bestSellers, query: `bestSeller_l+asc` },
  { name: staticTexts.priceDesc, query: `price+desc` },
  { name: staticTexts.priceAsc, query: `price+asc` },
  { name: staticTexts.atoz, query: "productName_s+asc" },
  { name: staticTexts.ztoa, query: "productName_s+desc" },
]

const handleKeyPress = (e, width, filterRef, cb) => {
  if (width < 992) {
    const focusElements =
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"]), button:not([disabled]) [role="button]:not([hidden=true])'
    const focusContent = filterRef.current.querySelectorAll(focusElements)
    const firstElem = focusContent[0]
    const lastElem = focusContent[focusContent.length - 1].disabled
      ? focusContent[focusContent.length - 2]
      : focusContent[focusContent.length - 1]
    if (e.key === "Escape") {
      cb(false)
    }
    if (e.key === "Tab") {
      if (
        e.shiftKey &&
        (document.activeElement === firstElem ||
          document.activeElement === filterRef.current)
      ) {
        e.preventDefault()
        lastElem.focus()
      }
      if (!e.shiftKey && document.activeElement === lastElem) {
        e.preventDefault()
        firstElem.focus()
      }
    }
  }
}

const scrollToProduct = () => {
  const urlParams = window.location.search
  let queryVal = ""
  let isBackSkuExists = false
  if (urlParams) {
    const urlQuery = urlParams.replace("?", "").split("&")
    urlQuery.forEach((query, i) => {
      const isBack = query.split("=")[0] === "back"
      if (isBack) {
        sessionStorage.plpScrollSku = query.split("=")[1]
        isBackSkuExists = true
      }
      queryVal += isBack ? "" : `${i > 0 ? "&" : ""}${query}`
    })
    window.history.replaceState(
      window.location.pathname,
      document.title,
      queryVal.length > 0 ? `?${queryVal.toString()}` : window.location.pathname
    )
    if (sessionStorage.plpScrollSku) {
      const scrollPosition = document.getElementById(
        sessionStorage.plpScrollSku
      )
      let IterCount = 10
      const scorllObjInterval = setInterval(() => {
        if (scrollPosition) {
          scrollPosition.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          })
          if (IterCount === 0) {
            delete sessionStorage.plpScrollSku
            clearInterval(scorllObjInterval)
          }
          IterCount--
        }
      }, 150)
    } else if (!isBackSkuExists) {
      scrollToFirstProduct(false, true)
    }
  } else {
    scrollToTop()
  }
}

const scrollToFirstProduct = (isFaq = false, shouldScroll = true) => {
  setTimeout(() => {
    const scrollPositions = isFaq
      ? document.querySelectorAll("div.product-listing")
      : document.querySelectorAll(".product-listing")
    if (scrollPositions && scrollPositions.length > 0 && shouldScroll) {
      window.scrollTo({
        top: scrollPositions[0].offsetTop,
        behavior: "smooth",
      })
    } else {
      scrollToTop()
    }
  }, 200)
}

const scrollToTop = () => {
  window.scrollTo(0, 0)
}
const getFlData = persona => [
  "id",
  "sku_s",
  "slug_s",
  "metaTitle_s",
  "productName_s",
  "masterSKU_s",
  "masterCustomerFacingSKU_s",
  "Product_Category",
  "ProductLocalCategory_s",
  "ProductBrandName_s",
  "productImages.url_s",
  "productImages.url_ss",
  "displayPriceStartingAt_s",
  "SKUColorSwatchFilename_s",
  "SKUColorSwatchFilename_ss",
  "ProductDescriptionProductShort_s",
  `priceList.${persona}.price_d`,
  `priceList.${persona}.finalPrice_d`,
  `priceList.${persona}.discountedPrice_d`,
  `priceList.${persona}.saleOffer_s`,
  `priceStartingAt.${persona}_d`,
  `discountedPriceStartingAt.${persona}_d`,
  "variantList.sku_ss",
  "variantList.sku_s",
  "SKU.Details_ss",
  "Color.SKU.Details_ss",
  "Color.SKU.Details_s",
  `Color.${persona}.Details_ss`,
  "ProductIsExclusive_s",
  "RegionReleaseforShipment_dt",
  "productImages.labelWithUrl_ss",
  "productImages.labelWithUrl_s",
  "variantList.sku_ss",
  "variantList.sku_s",
  "RegionOnBackOrder_s",
  "RegionBrandName_s",
  "ProductIsRetail",
  "comboSKUAvailable_b",
  "category1_s",
  "ProductBrandNameDisplay_s",
  "ctId_s",
  "ProductResource.InteractiveSVG.resourceName_ss",
  "ProductProductNo_s",
  "ProductResource.InteractiveSVG.resourceTitle_ss",
  "ProductResource.InstallationWithoutSPPDF.resourceFullWebURL_ss",
  "ProductResource.PartsPDFFileName.resourceFullWebURL_ss",
  resourceFacetLabels.join(","),
  "primaryBundleSKU_s",
  "bundleSKUList_ss",
]

const getFilterQuery = (type, query, queries = [], persona) => {
  const fq = type === "parts" ? [] : [query]
  queries.forEach(q => {
    const tag = `{!tag="${q.facet}"}`
    if (!q.facet.match(/^\*{2}/)) {
      q.facet === "Price_Range"
        ? fq.push(
            encodeURIComponent(
              `{!tag="priceList.${persona}.finalPrice_d"}priceList.${persona}.finalPrice_d:(${q.value.join(
                " OR "
              )})`
            )
          )
        : fq.push(
            encodeURIComponent(`${tag + q.facet}:("${q.value.join('" OR "')}")`)
          )
      return
    }
    q.facet === "Price_Range"
      ? fq.push(
          `priceList.${persona}.finalPrice_d:(${encodeURIComponent(q.value)})`
        )
      : fq.push(
          `${q.facet.replace(/^\*{2}/, "")}:("${encodeURIComponent(
            q.value.join('","')
          )}")`
        )
  })
  return fq
}

export {
  getSortList,
  handleKeyPress,
  getFlData,
  getFilterQuery,
  scrollToProduct,
  scrollToFirstProduct,
}
