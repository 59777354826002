import React, { useState } from "react"
import Sticky from "react-sticky-el"
import Sort from "@/components/ProductList/v2/Sort/index"
import ShowHide from "@/components/ProductList/v2/ProductListView/StickyNav/showHide"
import useWindowResize from "@/hooks/useWindowResize"
import closeIcon from "@/public/icons/close.svg"
import searchIcon from "@/public/icons/search.svg"

const StickyNavHeader = ({
  staticTexts = {},
  totalResults = 0,
  showFilter = false,
  sorts,
  selectedSort,
  setShowFilter = () => {},
  handleSort = () => {},
  isSearchTab,
  page,
  type = "",
  searchKeyword = "",
  onSearch,
  pageId,
  showTotal = true,
  isFaq = false,
}) => {
  const [navSticky, setNavSticky] = useState(false)
  const [width] = useWindowResize()

  return (
    <Sticky
      onFixedToggle={() => setNavSticky(!navSticky)}
      className="product-listing__sticky-nav-wrap"
      stickyStyle={{ zIndex: 9 }}
      stickyClassName={"sticky sticky-plp-listing"}
    >
      <div
        className={`container-fluid product-listing__sticky-nav ${
          navSticky && "product-listing__sticky-nav--shadow"
        }`}
      >
        <div
          className={`product-listing__row ${
            isSearchTab && "search-result__search-flex"
          }`}
        >
          {navSticky && width < 813 && isSearchTab && (
            <div className="search-result__search-wrap">
              <span>
                <img
                  role="presentation"
                  src={searchIcon.src}
                  alt="search-icon"
                />
              </span>
              <input
                type="text"
                value={searchKeyword}
                onChange={e => onSearch(e.target.value)}
              />
              <span onClick={() => onSearch("", false)}>
                <img src={closeIcon.src} alt="close-icon" />
              </span>
            </div>
          )}
          <div className="container kf-react-plp-container">
            <div className="product-listing__row">
              <div
                className={`product-listing__col product-listing__controls ${
                  isSearchTab && "search-result__sticky-search-pd"
                }`}
              >
                {isSearchTab ? (
                  navSticky ? (
                    <h2
                      aria-label={totalResults + staticTexts.resultsCaps}
                      className="product-listing__total"
                    >
                      {totalResults?.toLocaleString()} {staticTexts.resultsCaps}
                    </h2>
                  ) : (
                    <span></span>
                  )
                ) : showTotal ? (
                  <h2
                    aria-label={staticTexts?.totalResults}
                    className="product-listing__total"
                  >
                    {totalResults} {staticTexts?.results}
                  </h2>
                ) : (
                  <span></span>
                )}

                {navSticky && width > 812 && isSearchTab && (
                  <div className="search-result__sticky-search">
                    <span>
                      <img
                        role="presentation"
                        src={searchIcon.src}
                        alt="search-icon"
                      />
                    </span>
                    <input
                      type="text"
                      defaultValue={searchKeyword}
                      onChange={e => onSearch(e?.target?.value)}
                    />
                    <span onClick={() => onSearch("")}>
                      <img src={closeIcon.src} alt="close-icon" />
                    </span>
                  </div>
                )}
                {!pageId ? (
                  <section className={`sorting-section ${isFaq && "px-1"}`}>
                    {!isSearchTab && (
                      <span className="product-listing__fillter_sort">
                        {staticTexts?.filterSort}
                      </span>
                    )}

                    <ShowHide
                      showFilter={showFilter}
                      staticTexts={staticTexts}
                      setShowFilter={setShowFilter}
                      isSearchTab={isSearchTab}
                      page={page}
                      type={type}
                      isFaq={isFaq}
                    />
                    <Sort
                      tabIndex="0"
                      data={sorts}
                      selectedSort={selectedSort}
                      texts={staticTexts}
                      onChange={handleSort}
                      page={page}
                      selectedtab={type}
                      isSearch={isSearchTab}
                    />
                  </section>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Sticky>
  )
}
export default StickyNavHeader
