import React, { useState } from "react"
import { isMobile } from "react-device-detect"

import backToTopIcon from "@/public/icons/back-top-arrow.svg"
import printIcon from "@/public/icons/print.svg"
import shareIcon from "@/public/icons/share.svg"
import ProductsPerPage from "@/components/ProductList/v2/ProductListView/ProductsPerPage"
import SocialShare from "@/components/cart/OrderSummary/SocialShare"
import {
  defaultGetBackToTopAnalytics,
  plpGetBackToTopAnalytics,
  searchGetBackToTopAnalytics,
  technicalSpecGetBackToTopAnalytics,
} from "@/components/ProductList/v2/ProductListView/Pagination/analytics"
import { ConditionalWrapper } from "@/utils"

const Pagination = ({
  displayPrint = "",
  staticTexts = {},
  displayShare = "",
  curRows = 30,
  productsPerPage = [],
  totalResults = 0,
  onRows = () => {},
  handleBackToTop = () => {},
  width = 1280,
  isSearchTab = false,
  page = "",
  type = "",
  customClass = {},
  includeWrapper = true,
}) => {
  const { print = "", share = "", backToTop = "" } = staticTexts
  const [showSocialLink, setShowSocialLink] = useState(false)
  const { buttonClass = "", spanClass = "", imgClass = "" } = customClass ?? {}
  const getBackToTopAnalyticsData = () => {
    if (isSearchTab && page === "search")
      return searchGetBackToTopAnalytics(type)
    else if (page === "technicalspecs")
      return technicalSpecGetBackToTopAnalytics
    else if (page === "plp") return plpGetBackToTopAnalytics
    else return defaultGetBackToTopAnalytics
  }

  return (
    <ConditionalWrapper
      condition={includeWrapper}
      wrapper={children => (
        <div className="product-listing__row">
          <div className="product-listing__back-to-top">{children}</div>
        </div>
      )}
    >
      {isMobile && (
        <div className="product-listing__print-and-share">
          {displayPrint === "true" && (
            <span role="button" onClick={() => window?.print()}>
              {print}
              <img src={printIcon?.src} alt={print} />
            </span>
          )}
          {displayShare === "true" && (
            <span
              role="button"
              onClick={() => setShowSocialLink(!showSocialLink)}
            >
              {share}
              <img src={shareIcon?.src} alt={share} />
            </span>
          )}
          <SocialShare
            showModal={showSocialLink}
            id="kf-product-listing"
            onClose={() => setShowSocialLink(false)}
          />
        </div>
      )}
      {width > 1023 && (
        <ProductsPerPage
          pos="bottom"
          curRows={curRows}
          data={productsPerPage}
          totalResults={totalResults}
          texts={staticTexts}
          onChange={onRows}
          selectedTab={type}
          page={page}
        />
      )}
      <button
        role="link"
        className={`${
          buttonClass || "product-listing__go-back"
        } gbh-data-layer`}
        data-gbh-data-layer={getBackToTopAnalyticsData()}
        onClick={handleBackToTop}
      >
        <span className={`${spanClass || "product-listing__go-back-text"}`}>
          {backToTop}
        </span>
        <img
          className={`${imgClass || "product-listing__go-back-image"}`}
          aria-hidden="true"
          src={backToTopIcon?.src}
          alt={backToTop}
        />
      </button>
    </ConditionalWrapper>
  )
}

export default Pagination
