import React, { useEffect, useState } from "react"

import { DEFAULT_PRODUCT_COUNT_PLP } from "@/constants/index"
import Button from "@/components/core/Button/Button"
import { getLoadMoreAnalyticsData } from "@/components/ProductList/v2/ProductListView/Pagination/analytics.js"

const LoadMore = ({
  totalResults = 0,
  staticTexts = {},
  onLoadMore = () => {},
  curRows = 30,
  data = [],
  page = "",
  removeLoadAnimation = false,
}) => {
  const [buttonVisible, setButtonVisible] = useState(false)
  const transformClassName = removeLoadAnimation ? "no-transform" : ""
  useEffect(() => {
    if (
      totalResults > DEFAULT_PRODUCT_COUNT_PLP &&
      totalResults > curRows &&
      totalResults > data?.length
    ) {
      setButtonVisible(true)
    } else {
      buttonVisible && setButtonVisible(false)
    }
  }, [totalResults, DEFAULT_PRODUCT_COUNT_PLP, curRows, data?.length])
  return (
    <div
      className="product-listing__row gbh-data-layer"
      data-gbh-data-layer={getLoadMoreAnalyticsData(page)}
    >
      {buttonVisible && (
        <Button
          type="load-more"
          size="small"
          customClass={`product-listing__load-more ${transformClassName}`}
          label={staticTexts.loadMore}
          onClick={onLoadMore}
          parentClass={"div-wrapper"}
          role={"button"}
        />
      )}
    </div>
  )
}

export default LoadMore
