import { useState, useEffect } from "react"

const debounce = (fn, delay = 1000) => {
  let timerId = null
  return (...args) => {
    clearTimeout(timerId)
    timerId = setTimeout(() => fn(...args), delay)
  }
}

export const useDeviceWidthOnResize = width => {
  const [deviceWidth, setDeviceWidth] = useState(width ?? 0)

  const handleOnResize = debounce(function () {
    setDeviceWidth(window.innerWidth)
  }, 300)

  useEffect(() => {
    setDeviceWidth(window.innerWidth)
    window.addEventListener("resize", handleOnResize)
    return () => {
      window.removeEventListener("resize", handleOnResize)
    }
  }, [])

  return [deviceWidth]
}
